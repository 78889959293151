import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import "./App.scss";

declare module "styled-components" {
  export interface DefaultTheme {
    color: string;
    bgColor: string;
  }
}

const lightTheme = {
  color: "--bs-dark",
  bgColor: "--bs-white",
};

const darkTheme = {
  color: "--bs-light",
  bgColor: "--bs-dark",
};

const GlobalStyle = createGlobalStyle`
.fs-7 {
  font-size: 0.9rem !important;
}

.fs-8 {
  font-size: 0.8rem !important;
}

.fs-9 {
  font-size: 0.7rem !important;
}

.fs-10 {
  font-size: 0.6rem !important;
}

.fs-11 {
  font-size: 0.5rem !important;
}

.fs-12 {
  font-size: 0.4rem !important;
}

:root, .navbar-nav, .navbar {
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-body-font-family: var(--bs-font-sans-serif);
}

.navbar-nav {
	--bs-nav-link-hover-color: var(${(props) => props.theme.color});
	--bs-nav-link-color: var(${(props) => props.theme.color});
}

.navbar {
	--bs-navbar-active-color: var(${(props) => props.theme.color});
	--bs-navbar-brand-color: var(${(props) => props.theme.color});
	--bs-navbar-brand-hover-color: var(${(props) => props.theme.color});
	--bs-navbar-color: var(${(props) => props.theme.color});
}
`;

function App() {
  const [theme, setTheme] = useState(lightTheme);

  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container className="px-4 py-5">
        <Row className="align-items-center g-5 py-5">
          <Col lg={4}>
            <h1 className="display-5 fw-bold">Mighty Castle</h1>
            <p className="lead">You are visting {location.pathname}</p>
          </Col>
        </Row>
        <Row className="align-items-center g-5 py-5">
          <Col lg={4}>
            <Button
              onClick={() => {
                window.location.href = 'https://registry.mightycastle.net/jellyfin/SSO/oid/p/Authelia';
              }}
            >
              Login to Jellyfin
            </Button>
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  );
}

export default App;
